<!--
    组件说明: 带数据上传的表单
           propertys:       value(必须）所有配置参数options
                            id         如果是修改需要绑定id,用来初始化数据和判断新增/修改
           options具体参数:  postUrl   数据新增地址(string/function)
                            putUrl    数据修改地址(string/function)
                            getOneUrl 数据修改打开时请求数据地址(string/function)
                            items     表单具体各项配置
                            rules     表单校验规则
           events:          close     关闭事件, 用于外部关闭对话框, 数据更新成功会传递参数1
                            save      保存事件, 如果postUrl为空, 会发出该事件, 参数为editForm
                            update    更新事件, 如果putUrl为空, 会发出该事件, 参数为editForm
-->
<template>
    <el-form :model="editForm" :rules="rules" ref="editForm">
        <el-descriptions class="margin-top"  :column="(this.items&&this.items.length>7)?2:1"  border>
            <slot name="header"></slot>
            <template v-for="item in items">
                <el-descriptions-item v-if="item.type === Search_Type.Input">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-input v-model="editForm[item.name]" :disabled="id && item.readonly"></el-input>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item v-else-if="item.type === Search_Type.Select">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-select v-model="editForm[item.name]" :placeholder="'选择' + item.alias?item.alias:item.label">
                            <el-option v-for="(label,value,index) in item.props" :key="index" :label="label" :value="value" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item v-else-if="(item.type === Search_Type.DynamicSelect) && (item.needId?id:true)">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-select v-model="editForm[item.name]" :placeholder="'选择' + (item.alias?item.alias:item.label).replace('列表','')">
                            <el-option v-for="(prop,index) in item.props" :key="index" :label="prop[item.p2]" :value="prop[item.p1]" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item v-else-if="item.type===Search_Type.Date||item.type===Search_Type.Datetime">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-date-picker
                                v-model="editForm[item.name]"
                                :type="item.type"
                                :value-format="item.type===Search_Type.Date?'yyyy-MM-dd':'yyyy-MM-dd HH:mm:ss'"
                                :placeholder="item.alias?item.alias:item.label"
                                :picker-options="PickerOptions.Base"
                                style="width: 100%;"></el-date-picker>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item v-else-if="item.type === Search_Type.Province">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-select v-model="editForm[item.name]" :placeholder="'选择' + item.alias?item.alias:item.label" @change="chooseProvince(item.name)">
                            <el-option v-for="(province,index) in provinceList" :key="index" :label="province.provinceName" :value="province.id" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item v-else-if="item.type === Search_Type.City">
                    <template slot="label">{{item.alias?item.alias:item.label}}</template>
                    <el-form-item :prop="item.name">
                        <el-select v-model="editForm[item.name]" :placeholder="'选择' + item.alias?item.alias:item.label">
                            <el-option v-for="(city,index) in cityList" :key="index" :label="city.city" :value="city.id" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
            </template>
            <slot></slot>
        </el-descriptions>
        <el-form-item size="large">
            <el-button type="primary" v-if="items&&items.length>0" @click="submitHandle('editForm')">确认</el-button>
            <el-button @click="cancelHandle('editForm')">取消</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import {Search_Type, PickerOptions} from '../../constants';
    export default {
        props: {
            value: {
                type: Object,
                required: true
            },
            id: {
                type: String,
                required: false
            },
        },
        data(){
            return {
                editForm: {},
                provinceList: [],
                cityList: [],
                Search_Type,
                PickerOptions
            }
        },
        methods: {
            submitHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.id){
                            if(this.putUrl){
                                let putUrl = this.concatUrl(this.putUrl, this.id)
                                if(!putUrl){
                                    this.$message.error('修改地址错误')
                                    return
                                }
                                this.$axios.put(putUrl,this.editForm
                                ).then(()=>{
                                    this.$message({
                                        showClose: true,
                                        message: '恭喜你，操作成功',
                                        type: 'success',
                                        duration: 1500,
                                        onClose:() => {
                                            this.$emit('close', 1)
                                        }
                                    });
                                });
                            }else{
                                this.$emit('update', this.editForm)
                            }
                        }else{
                            if(this.postUrl){
                                let postUrl = this.concatUrl(this.postUrl, this.id)
                                this.$axios.post(postUrl, this.editForm).then(()=>{
                                    this.$message({
                                        showClose: true,
                                        message: '恭喜你，操作成功',
                                        type: 'success',
                                        duration: 1500,
                                        onClose:() => {
                                            this.$emit('close', 1)
                                        }
                                    });
                                });
                            }else{
                                this.$emit('save', this.editForm)
                            }
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            concatUrl(urlObject, id){
                let url = null;
                if(typeof(urlObject) === 'string'){
                    if(id){
                        if(urlObject.indexOf('{id}') !== -1){
                            url = urlObject.replace('{id}', id)
                        }else{
                            url = `${urlObject}/${id}`
                        }
                    }else{
                        url = urlObject
                    }
                }else if(typeof(urlObject) === 'function'){
                    if(id){
                        url = urlObject(id)
                    }else{
                        url = urlObject()
                    }
                }
                return url
            },
            cancelHandle(){
                this.$emit('close')
            },
            chooseProvince(itemName){
                let provinceId = this.editForm[itemName]
                let province = this.provinceList.find(province=>province.id===provinceId)
                if(province){
                    this.cityList = province.cityList
                }else{
                    this.cityList = []
                }
                //同时更新cityId
                let item = this.items.find(item=>item.type===Search_Type.City)
                if(item){
                    let cityData = {}
                    cityData[item.name] = this.cityList.length>0?this.cityList[0].id:undefined;
                    this.editForm = {...this.editForm, ...cityData}
                }
            },
            loadFormData(){
                if(this.id){
                    this.loadDynamicSelectData(this.id)
                    let url = this.concatUrl(this.getOneUrl, this.id)
                    if(!url){
                        this.$message.error("加载数据失败");
                        return ;
                    }
                    this.$axios.get(url).then(res=>{
                        this.editForm = res.data;
                        //select类型必须是string,否则无法默认选中
                        if(this.items){
                            this.items.forEach(item=>{
                                if(item.type && item.type === Search_Type.Select){
                                    if(Number.isInteger(this.editForm[item.name])){
                                        this.editForm[item.name] = ''+this.editForm[item.name];
                                    }
                                }else if(item.type === Search_Type.Province){
                                    //初始化界面省市的正确显示
                                    let provinceId = this.editForm[item.name]
                                    let province = this.provinceList.find(province=>province.id===provinceId)
                                    if(province){
                                        this.cityList = province.cityList
                                    }else{
                                        this.cityList = []
                                    }
                                }else{
                                    if(this.editForm[item.name]){
                                      this.editForm[item.name] = ''+this.editForm[item.name];
                                    }
                                }
                            })
                        }
                    })
                }else{
                    let initForm = {}
                    this.items.filter(item=>item.type === Search_Type.Select).forEach(item=>{
                        if(item.defaultValue && item.props){
                            for(let key in item.props){
                                if(item.defaultValue === (''+key)){
                                    initForm[item.name] = item.defaultValue
                                }
                            }
                        }
                    })
                    this.editForm = initForm
                }
            },
            loadDynamicSelectData(id){
                //加载动态下拉框数据
                this.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl) return;
                    //如果动态地址带id参数,每次id变化需重新加载
                    item.needId = dynamicUrl.indexOf('{id}')!==-1;
                    if(item.needId){
                        if(!id) return;
                        dynamicUrl = this.concatUrl(dynamicUrl, id)
                    }else{
                        //加载过了不重复加载
                        if(item.props) return;
                    }
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                            //强制刷新不然看不见
                            this.$forceUpdate()
                            // console.log('forceUpdate')
                        }catch (e) {
                            this.$message.error(`${item.label}获取失败`);
                            item.props = null
                        }
                    })
                })
            },
        },
        computed:{
            options: {
                get(){
                    return this.value;
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            postUrl: {
                get(){
                    return this.options.postUrl;
                }
            },
            putUrl: {
                get(){
                    return this.options.putUrl;
                }
            },
            getOneUrl: {
                get(){
                    return this.options.getOneUrl;
                }
            },
            items: {
                get(){
                    return this.options.items;
                }
            },
            rules: {
                get(){
                    return this.options.rules;
                }
            }
        },
        watch:{
            id:{
                deep: true,
                handler: function (newVal, oldVal) {
                    // console.log('watch')
                    this.loadFormData();
                }
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                // 仅在整个视图都被渲染之后才会运行的代码
                // console.log('mounted')
                this.loadFormData();
            })
        },
        created() {
            // console.log('created')
            if(this.items.filter(item=>item.type === Search_Type.Province).length>0){
                this.provinceList = this.g_getProvinceList()
            }
            this.loadDynamicSelectData();
        }

    }
</script>

<style scoped>
    :deep(.el-form-item){
        margin-bottom: 0;
    }
    /*/deep/ .el-form-item{*/
    /*    margin-bottom: 0;*/
    /*}*/
</style>